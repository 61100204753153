<template>
  <div>
    <v-img contain height="80vh" src="/404.svg"></v-img>
    <div style="display: flex; justify-content: center">
      <v-btn outlined color="primary" rounded @click="$router.go(-2)">Назад</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped>

</style>
